import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  h1 {
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;

    @media(max-width: 650px) {
      font-size: 1.5rem;
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  &::after {
    content: '';
    margin-top: 0.25rem;
    width: 18%;
    border-bottom: 2px solid ${({ theme }) => theme.blue};
  }

  ${(props) => props.isDark
    && css`
      h1, h1 svg {
        color: ${({ theme }) => theme.timberwolf};
      }
      &::after {
        border-bottom: 2px solid ${({ theme }) => theme.earthYellow};
      }
    `}
`;
