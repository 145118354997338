import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from '~/styles/global'
import { main } from '../../styles/themes'

import Sidebar from '~/components/Sidebar'
import Footer from '~/components/Footer'
import { Container, Content } from './styles'

const Layout = ({ children }) => (
  <ThemeProvider theme={main}>
    <Container>

      <Sidebar />

      <Content>{children}</Content>

      <Footer />
      <GlobalStyle />
    </Container>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
