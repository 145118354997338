import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.blueBackground};
  padding: 40px 0;

  > span {
    padding-top: 40px;
    color: ${({ theme }) => theme.timberwolf};
    font-weight: 400;
    font-size: 14px;

    strong {
      font-weight: bold;
      padding-right: 2px;
    }
  }

  ${media.lessThan('large')`
    padding: 20px;

    > span {
      font-size: 12px;
      padding-top: 0;
    }
  `};
`;

export const Content = styled.aside`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  width: 100%;
  max-width: 1140px;

  > div {
    display: flex;
    flex-direction: column;

    h1 {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      color: ${({ theme }) => theme.timberwolf};
      margin: 0 0 20px;
      &::after {
        content: '';
        margin-top: 2px;
        width: 12%;
        border-bottom: 2px solid ${({ theme }) => theme.earthYellow};
      }
    }

    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      line-height: 24px;
    }

    ul li {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      margin-bottom: 13px;

      svg {
        width: 14px;
        height: 14px;
        color: rgba(255, 255, 255, 0.7);
        margin-right: 6px;
      }

      a {
        color: ${({ theme }) => theme.earthYellow};
      }
    }
  }

  ${media.lessThan('large')`
    grid-template-columns: 1fr;

    > div {
      margin-bottom: 20px;

      h1 {
        font-size: 22px;
      }
    }
  `};
`;

export const AreasDeAtuacao = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  a {
    color: ${({ theme }) => theme.earthYellow};
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
    transition: transform ease 0.8s;

    &:hover {
      transform: translateX(7px);
    }
  }
`;
