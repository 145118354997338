import Img from 'gatsby-image'
import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { FaFacebookSquare, FaWhatsapp } from 'react-icons/fa'

import { socialmedia, routes } from './content'
import { Container, Nav, NavContent, NavList, NavItem, SocialMedia, NavToggle } from './styles'

const QUERY_LOGO = graphql`
{
  desktopSize: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(height: 90, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  mobileSize: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(height: 50, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}`

const Sidebar = () => {
  const { desktopSize, mobileSize } = useStaticQuery(QUERY_LOGO)

  const [isClose, setIsClose] = useState(false)

  return (
    <Container>
      <Nav>
        <AniLink
          paintDrip
          hex="#222831"
          duration={0.6}
          direction="left"
          to="/"
        >
          <Img fixed={desktopSize.childImageSharp.fixed} alt="Logo CasaGrande Advogados" />
          <Img fixed={mobileSize.childImageSharp.fixed} alt="Logo CasaGrande Advogados" />
        </AniLink>

        <NavContent visible={isClose}>
          {/* Itens do menu */}
          <NavList>
            {routes.map((navItem) => (
              <NavItem key={navItem.id}>
                <AniLink
                  paintDrip
                  hex="#222831"
                  duration={0.6}
                  direction="left"

                  to={navItem.url}
                  aria-label={navItem.id}
                  activeStyle={{ fontWeight: 'bold' }}
                >
                  {navItem.content}
                </AniLink>
              </NavItem>
            ))}
          </NavList>
          {/* Link's redes sociais */}
          <SocialMedia>
            <a href={socialmedia.facebook} target="_new">
              <FaFacebookSquare />
            </a>
            <a href={socialmedia.whatsapp} target="_new">
              <FaWhatsapp />
            </a>
          </SocialMedia>
        </NavContent>

        <NavToggle
          isClose={isClose}
          onClick={() => setIsClose((state) => !state)}
        >
          <span />
          <span />
          <span />
        </NavToggle>
      </Nav>
    </Container>
  )
}

export default Sidebar
