
import styled, { css } from 'styled-components'

export const Container = styled.nav`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1rem 0 0.9rem;
  background: #fff;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`

export const Nav = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  > a {
    div:last-child {
      display: none!important;
      visibility: hidden!important;
    }

    @media (max-width: 768px) {
      z-index: 11;
      
      div:first-child {
        display: none!important;
        visibility: hidden!important;
      }

      div:last-child {
        display: block!important;
        visibility: visible!important;
      }
    }
  }

  @media (max-width: 1080px) {
    padding: 0 1rem;
  }
`

export const NavContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  visibility: visible;

  @media (max-width: 768px) {
    flex-direction: column;

    position: fixed;
    top: 0;
    left: ${(props) => (props.visible ? 0 : -768)}px;
    z-index: 10;

    width: 100%;
    height: 100%;
    padding: 6rem 0 2rem;
    background: ${({ theme }) => theme.blueBackground};

    transition: all 0.8s;
  }
`

export const NavList = styled.ul`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 3rem;
  list-style: none;

  @media (max-width: 1080px) {
    padding: 0 2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    padding: 0 0 4rem;
  }
`

export const NavItem = styled.li`
  position: relative;

  a {
    color: ${({ theme }) => theme.blueBackground};
    font-size: 1.25rem;
    font-weight: 400;
    text-decoration: none;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      color: #fff;
    }
  }

  &:after {
    content: '';
    display: block;

    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;

    margin: 0 auto;

    width: ${(props) => (props.active ? 60 : 0)}%;
    height: 1px;
    background: ${({ theme }) => theme.blueBackground};

    transition: all ease 0.4s;
  }

  &:hover {
    &:after {
      width: 60%;
    }
  }
`

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a:not(:first-child) {
    margin-left: 0.75rem;
  }

  svg {
    color: ${({ theme }) => theme.blueBackground};
    width: 1.625rem;
    height: auto;
  }

  @media (max-width: 768px) {
    margin-top: auto;
    justify-content: center;

    a:not(:first-child) {
      margin-left: 1rem;
    }

    svg {
      width: 2rem;
    }
  }
`

export const NavToggle = styled.div`
  display: none;
  flex-direction: column;

  width: 30px;
  margin-left: auto;

  visibility: hidden;
  z-index: 999;

  @media (max-width: 768px) {
    display: flex;
    visibility: visible;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    display: inline;

    width: 30px;
    height: 0.25rem;
    background: ${({ theme }) => theme.blueBackground};
    transition: all ease 0.6s;
  }

  span:nth-child(2) {
    margin: 5px 0;
  }

  ${(props) => props.isClose &&
    css`
      span {
        background: #fff;
      }
      span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 9px);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(-45deg) translate(3px, -8px);
      }
    `}
`
