export const socialmedia = {
  facebook: 'https://www.facebook.com/casagrandeadvogados/',
  whatsapp: 'https://web.whatsapp.com/send?phone=5547996512446&text=Olá%20CasaGrande%20Advogados.&source=&data='
};

export const routes = [
  {
    id: 'home',
    content: 'Inicio',
    url: '/'
  },
  {
    id: 'about',
    content: 'Quem Somos',
    url: '/sobre'
  },
  {
    id: 'areas-expertise',
    content: 'Áreas de Atuação',
    url: '/areas-de-atuacao'
  },
  {
    id: 'academic-contribution',
    content: 'Contribuições Acadêmicas',
    url: '/contribuicoes-academicas'
  },
  {
    id: 'news',
    content: 'Notícias',
    url: '/noticias'
  },
  {
    id: 'contact',
    content: 'Contato',
    url: '/#container-footer'
  }
];
