import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Title = ({ isDark = false, content, icon }) => (
  <Container isDark={isDark}>
    <h1>{icon}{content}</h1>
  </Container>
);

Title.defaultProps = { isDark: false };

Title.propTypes = {
  isDark: PropTypes.bool,
  content: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

export default Title;
