import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FaEnvelope, FaFacebookSquare, FaPhoneSquareAlt, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';

import { Container, Content, AreasDeAtuacao } from './styles';

function Footer() {
  const areas = [
    'Direito Empresarial',
    'Previdência Social',
    'Direito de Família',
    'Análise de Contratos',
    'Direito Trabalhista',
    'Sustentações Orais',
    'Direito Tributário',
    'Sucessões',
    'Direito Civil'
  ];

  return (
    <Container id="container-footer">
      <Content>
        <div>
          <h1>SOBRE</h1>
          <p>
            Foi constituída em 1999 com atuação dirigida às Organizações. Assim, o Escritório conta
            com larga experiência na prestação de serviços em várias áreas de direito e em qualquer
            ponto do país, inclusive com ampla atuação perante os Tribunais Superiores.
          </p>
        </div>
        <div>
          <h1>ÁREAS DE ATUAÇÃO</h1>
          <AreasDeAtuacao>
            {areas.map((area, index) => <AniLink key={Number(index)} to="/areas-de-atuacao">{area}</AniLink>)}
          </AreasDeAtuacao>
        </div>
        <div>
          <h1>CONTATO</h1>
          <ul>
            <li><FaEnvelope />joinville@casagrande-advogados.adv.br</li>
            <li><FaPhoneSquareAlt />(047) 3205-1200</li>
            <li><FaWhatsapp />(047) 99651-2446</li>
            <li>
              <FaFacebookSquare />
              <a href="https://www.facebook.com/casagrandeadvogados/" target="__new">@casagrandeadvogados</a>
            </li>
            <li>
              <FaMapMarkerAlt />
              Rua Dr. Plácido Olímpio de Oliveira, 875 - Anita Garibaldi, Joinville - SC
            </li>
          </ul>
        </div>
      </Content>
      <span>
        <strong>Casagrande Advogados</strong>
        © {new Date().getFullYear()} - Todos os direitos reservados
      </span>
    </Container>
  );
}

export default Footer;
