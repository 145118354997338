import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  input, textarea, button, body {
    color: #333;
    font: 400 1rem "Roboto", sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .react-modal-overlay {
    background: rgba(0,0,0,0.6);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;
  }

  .react-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    max-width: 1080px;
    max-height: 720px;

    padding: 1rem;

    position: relative;
    background: transparent;

    @media (max-width: 720px) {
      padding: 0 0.5rem;
    }
  }
`

export default GlobalStyles
